import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='Privacy Policy' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Privacy Policy'
            subheader='Our website address is: https://possiblefix.com'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Button as={GLink} to='/terms' sx={styles.button}>
              Check Terms and Conditions
            </Button>
          </Box>
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section>
                <Text variant='h2'>
                  Comments
                </Text>
                <Text variant='p'>
                  <b>Suggested text:</b>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.

An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.
                   </Text>
                <Text variant='h2'>
                  Media
                </Text>
                <Text variant='p'>
                <b>Suggested text:</b> If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.
                  <ul>
                    <li>test</li>
                    <li>Mucius sensibus</li>
                    <li>Sit primis iudicabit</li>
                  </ul>
                </Text>
                <Text variant='h2'>
                  Cookies
                </Text>

                <Text variant='p'>
                 <b>Suggested text:</b> If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.
    </Text>
     <Text variant='p'>
If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
    </Text>
       <Text variant='p'>
When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.
    </Text>
         <Text variant='p'>
If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
                </Text>
                <Text variant='h2'>
                Embedded content from other websites

                </Text>
                  <Text variant='p'>
<b>Suggested text:</b>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
   </Text>
                  <Text variant='p'>
These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
                </Text>

                  <Text variant='h2'>
                Who we share your data with
                </Text>  

                <Text variant='p'>
                <b>Suggested text:</b> If you request a password reset, your IP address will be included in the reset email.
                </Text> 

                 <Text variant='h2'>
                How long we retain your data
                </Text> 

                 <Text variant='p'>
                <b>Suggested text:</b> If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.
   </Text>
                  <Text variant='p'>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.
                </Text>

               <Text variant='h2'>
                What rights you have over your data
                </Text> 

                <Text variant='p'>
<b>Suggested text:</b> If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
                </Text> 
                <Text variant='h2'>
                Where your data is sent
                   </Text> 
                         <Text variant='p'>
                <b>Suggested text:</b> Visitor comments may be checked through an automated spam detection service.


                   </Text> 
              </Section>
            </Box>
           

          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
